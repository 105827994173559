import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Http from './../helpers/http';
import { type Media } from '../models/media';
import type Pagination from './../models/pagination';
import type { ProductVariantValue, NewProduct } from './../models/product';
import type { User } from '../models/staff';
import { Prettify } from '../models/common';
import { apiBaseUrl } from '../constants/variables';

type MultipleLangData = {
  en: string;
};

type AttributeData = {
  name: MultipleLangData;
  description: MultipleLangData;
};

export type Product = {
  id: number;
  product_type_id: number;
  status: string;
  attribute_data: AttributeData;
  brand: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
  variants_count: number;
  media: Media[];
};
interface Staff extends User {
  departments?: {
    id: number;
    name: string;
  }[];
}
export type priceChangeList = {
  id: number;
  staff_id: number;
  variant_id: number;
  changes: string;
  change_date: string;
  created_at: string;
  updated_at: string;
  staffs?: Prettify<Staff>;
  variants: {
    id: number;
    product_id: number;
    status: string;
    product: {
      attribute_data: AttributeData;
    };
    values: ProductVariantValue[];
    media: Media[];
  };
};
export type RatingType = {
  id: number;
  product_id?: number;
  rating: number;
  title: string;
};
type ProductState = {
  productList: {
    loading: boolean;
    data: Product[];
    error?: string | null;
    meta?: Pagination;
  };
  createProduct: {
    loading: boolean;
    newlyCreated?: number | null;
  };
  priceContributorList: {
    loading: boolean;
    data: {
      id: number;
      firstname: string;
      lastname: string;
      departments: string;
      count: number;
    }[];
    error?: string | null;
  };
  priceChangeList: {
    loading: boolean;
    meta?: Pagination;
    list: priceChangeList[];
    error?: string | null;
  };
  priceContributor: {
    loading: boolean;
    meta?: Pagination;
    list: {
      id: number;
      staff_id: number;
      variant_id: number;
      changes: string;
      change_date: string;
      created_at: string;
      updated_at: string;
      variants: {
        id: number;
        product_id: number;
        status: string;
        product: {
          attribute_data: AttributeData;
        };
        media: Media[];
      };
    }[];
    staff?: Staff;
    error?: string | null;
  };
  ratingList: {
    loading: boolean;
    list: RatingType[];
    error?: string | null;
  };
};

const initialState: ProductState = {
  productList: {
    loading: false,
    data: [],
  },
  createProduct: {
    loading: false,
  },
  priceContributorList: {
    loading: false,
    data: [],
  },
  priceContributor: {
    loading: false,
    list: [],
  },
  priceChangeList: {
    loading: false,
    list: [],
  },
  ratingList: {
    loading: false,
    list: [],
  },
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    fetchProductsRequest: (state) => {
      state.productList.loading = true;
    },
    fetchProductsSuccess: (state, action) => {
      state.productList = {
        loading: false,
        data: action.payload.data,
        error: null,
        meta: action.payload.meta,
      };
    },
    fetchProductsFailure: (state, action) => {
      state.productList.loading = false;
      state.productList.error = action.payload;
    },
    //PRODUCT DELETE
    deleteProductSuccess: (state, action) => {
      state.productList.data = state.productList.data.filter(
        (prod) => prod.id !== action.payload
      );
    },
    //PRODUCT CREATE
    createProductRequest: (state) => {
      state.createProduct.loading = true;
    },
    createProductSuccess: (state, action) => {
      state.createProduct = {
        loading: false,
        newlyCreated: action.payload.id,
      };
    },
    createProductFailure: (state) => {
      state.createProduct = {
        loading: false,
        newlyCreated: null,
      };
    },
    clearNewlyCreated: (state) => {
      state.createProduct.newlyCreated = undefined;
    },
    //Price Contributor List
    priceContributorListRequest: (state) => {
      state.priceContributorList.loading = true;
    },
    priceContributorListSuccess: (state, action) => {
      state.priceContributorList = {
        loading: false,
        data: action.payload.data,
      };
    },
    priceContributorListFailure: (state, action) => {
      state.priceContributorList = {
        loading: false,
        data: [],
        error: action.payload,
      };
    },
    //Price Contributor
    priceContributorRequest: (state) => {
      state.priceContributor.loading = true;
    },
    priceContributorSuccess: (state, action) => {
      state.priceContributor = {
        loading: false,
        list: action.payload.data,
        meta: action.payload.meta,
        staff: action.payload.staff,
      };
    },
    priceContributorFailure: (state, action) => {
      state.priceContributor = {
        loading: false,
        list: [],
        error: action.payload,
      };
    },
    //Price Contributor
    priceChangeListRequest: (state) => {
      state.priceChangeList.loading = true;
    },
    priceChangeListSuccess: (state, action) => {
      state.priceChangeList = {
        loading: false,
        list: action.payload.data,
        meta: action.payload.meta,
      };
    },
    priceChangeListFailure: (state, action) => {
      state.priceContributor = {
        loading: false,
        list: [],
        error: action.payload,
      };
    },
    //Rating List
    ratingListRequest: (state) => {
      state.ratingList.loading = true;
    },
    ratingListSuccess: (state, action) => {
      state.ratingList = {
        loading: false,
        list: action.payload,
      };
    },
    ratingListFailure: (state, action) => {
      state.ratingList = {
        loading: false,
        list: [],
        error: action.payload,
      };
    },
    ratingCreateSuccess: (state, action) => {
      state.ratingList.list = [...state.ratingList.list, action.payload];
    },
    ratingUpdateSuccess: (state, action) => {
      state.ratingList.list = state.ratingList?.list?.map((rating) =>
        rating.id === action.payload.id ? action.payload : rating
      );
    },
    ratingDeleteSuccess: (state, action) => {
      state.ratingList.list = state.ratingList?.list?.filter(
        (rating) => rating.id !== action.payload.id
      );
    },
  },
});

export const productActions = productSlice.actions;
export default productSlice.reducer;

//ACTIONS
export const fetchProductsAction =
  (page = 1, pageSize = 20) =>
  async (dispatch: any) => {
    dispatch(productActions.fetchProductsRequest());
    try {
      const response = await Http.Get({
        path: `products?page=${page}&limit=${pageSize}`,
        useAuth: true,
      });
      if (!response.ok) {
        // Log the error response text (HTML or JSON)
        const errorResponseText = await response.text();
        console.error(`API Error Response: ${errorResponseText}`);
        throw new Error(`API request failed with status ${response.status}`);
      }
      // Check if the response is JSON
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const errorResponseText = await response.text();
        console.error(
          `Invalid Content-Type. Expected JSON but got: ${contentType}`
        );
        console.error(`Response Body: ${errorResponseText}`);
        throw new Error(
          `Invalid Content-Type. Expected JSON but got: ${contentType}`
        );
      }
      const result = await response.json();
      dispatch(
        productActions.fetchProductsSuccess({
          data: result.data,
          meta: result.meta,
        })
      );
    } catch (error: any) {
      // Log the full error details
      console.error('Error:', {
        message: error.message,
        stack: error.stack,
        apiUrl: `${apiBaseUrl}/products`,
      });
      dispatch(productActions.fetchProductsFailure(error.message));
    }
  };

export const deleteProductAction =
  (productId: number) => async (dispatch: any) => {
    try {
      const response = await Http.Delete({
        path: `products/${productId}`,
        useAuth: true,
      });
      if (!response.ok) {
        throw new Error('Something wrong');
      }
      toast.success('Deleted');
      dispatch(productActions.deleteProductSuccess(productId));
    } catch (error: any) {
      toast.error(error.message);
    }
  };

export const createProductAction =
  (data: NewProduct) => async (dispatch: any) => {
    dispatch(productActions.createProductRequest());
    try {
      const response = await Http.Post({
        path: `products`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error(
          result.message
            ? JSON.stringify(result.message)
            : 'Fail to create product'
        );
      }
      dispatch(productActions.createProductSuccess(result));

      // console.log(result);
    } catch (error: any) {
      dispatch(productActions.createProductFailure());
      try {
        const errors = Object.entries(JSON.parse(error.message));
        errors.forEach((err: any) => toast.error(err[1][0]));
      } catch (e) {
        toast.error(error.message);
      }
    }
  };

export const searchProductAction =
  ({
    data,
    pageNo = 1,
    pageSize = 20,
  }: {
    data: any;
    pageNo?: number;
    pageSize?: number;
  }) =>
  async (dispatch: any) => {
    try {
      const response = await Http.Post({
        path: `search/products?page=${pageNo}&limit=${pageSize}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }

      dispatch(
        productActions.fetchProductsSuccess({
          data: result.data,
          meta: result.meta ? result.meta : undefined,
        })
      );
    } catch (error: any) {
      throw error;
    }
  };

export const fetchPriceContributorListAction =
  (data?: { date_from: string; date_to: string }) => async (dispatch: any) => {
    dispatch(productActions.priceContributorListRequest());
    try {
      const response = await Http.Post({
        path: `price_chanage/list`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(
        productActions.priceContributorListSuccess({
          data: result,
        })
      );
    } catch (error: any) {
      dispatch(productActions.priceContributorListFailure(error.message));
    }
  };

export const fetchPriceContributorAction =
  (id: string, pageNo: number) => async (dispatch: any) => {
    dispatch(productActions.priceContributorRequest());
    try {
      const response = await Http.Get({
        path: `price_chanage/${id}?page=${pageNo}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(
        productActions.priceContributorSuccess({
          data: result.changes.data,
          staff: result.staff,
          meta: result.changes.links.length
            ? {
                current_page: result.changes.current_page,
                first_page_url: result.changes.first_page_url,
                from: result.changes.from,
                last_page: result.changes.last_page,
                last_page_url: result.changes.last_page_url,
                links: result.changes.links,
                next_page_url: result.changes.next_page_url,
                path: result.changes.path,
                per_page: result.changes.per_page,
                prev_page_url: result.changes.prev_page_url,
                to: result.changes.to,
                total: result.changes.total,
              }
            : undefined,
        })
      );
    } catch (error: any) {
      dispatch(productActions.priceContributorFailure(error.message));
    }
  };

export const fetchChangeListAction =
  (pageNo = 1) =>
  async (dispatch: any) => {
    dispatch(productActions.priceChangeListRequest());
    try {
      const response = await Http.Get({
        path: `price_chanage?page=${pageNo}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(
        productActions.priceChangeListSuccess({
          data: result.data,
          meta: result.meta ? result.meta : undefined,
        })
      );
    } catch (error: any) {
      dispatch(productActions.priceChangeListFailure(error.message));
    }
  };
export const fetchRatingListAction =
  (productId: string) => async (dispatch: any) => {
    dispatch(productActions.ratingListRequest());
    try {
      const response = await Http.Get({
        path: `products/${productId}/rating/`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(productActions.ratingListSuccess(result));
    } catch (error: any) {
      dispatch(productActions.ratingListFailure(error.message));
    }
  };
export const createRatingAction =
  ({ productId, data }: { productId: string; data: any }) =>
  async (dispatch: any) => {
    dispatch(productActions.ratingListRequest());
    try {
      const response = await Http.Post({
        path: `products/${productId}/rating`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(productActions.ratingCreateSuccess(result));
    } catch (error: any) {
      dispatch(productActions.ratingListFailure(error.message));
    }
  };
export const fetchRatingUpdateAction =
  ({
    productId,
    ratingId,
    data,
  }: {
    productId: string;
    ratingId: number;
    data: any;
  }) =>
  async (dispatch: any) => {
    dispatch(productActions.ratingListRequest());
    try {
      const response = await Http.Put({
        path: `products/${productId}/rating/${ratingId}`,
        data,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(productActions.ratingUpdateSuccess(result));
    } catch (error: any) {
      dispatch(productActions.ratingListFailure(error.message));
    }
  };

export const deleteRatingAction =
  ({ productId, ratingId }: { productId: string; ratingId: number }) =>
  async (dispatch: any) => {
    dispatch(productActions.ratingListRequest());
    try {
      const response = await Http.Delete({
        path: `products/${productId}/rating/${ratingId}`,
        useAuth: true,
      });
      const result = await response.json();
      if (!response.ok) {
        throw new Error('Product fetching failed');
      }
      dispatch(productActions.ratingDeleteSuccess(result));
    } catch (error: any) {
      dispatch(productActions.ratingListFailure(error.message));
    }
  };
